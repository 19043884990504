// import React from "react";
// import styled from "styled-components";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import Caddy from "../../assets/images/Caddy.jpg";
// import ScorecardWMap from "../../assets/images/Scorecard-w-map.jpg";
// import MeasureShot from "../../assets/images/Measure-shot.jpg";
// import ContinueRound from "../../assets/images/Continue-round.jpg";
// import DistanceToGreen from "../../assets/images/Distance-to-green.jpg";
// import Video from "../../assets/images/Video.jpg";
// import Video2 from "../../assets/images/Video-2.jpg";
// import Practice from "../../assets/images/Practice.jpg";
// import PracticeLog from "../../assets/images/Practice-log.jpg";
// import LogStats from "../../assets/images/Log-stats.jpg";
// import Goals from "../../assets/images/Goals.jpg";
// import Notes from "../../assets/images/Notes.jpg";
// import Aimpoint from "../../assets/images/Aimpoint.jpg";
// import Aimpoint2 from "../../assets/images/Aimpoint-2.jpg";
// import Stats from "../../assets/images/Stats.jpg";
// import Stats2 from "../../assets/images/Stats-2.jpg";
// import FilterStats from "../../assets/images/Filter-stats.jpg";
// import PutterStats from "../../assets/images/Putterstats.jpg";
// import PutterStats2 from "../../assets/images/Putterstats-2.jpg";
// import MapAndDistances from "../../assets/images/Map-and-distances.jpg";
// import ShotStats from "../../assets/images/Shot-stats.jpg";
// import SelectTee from "../../assets/images/SelectTee.jpg";
// import SearchCourses from "../../assets/images/SearchCourses.jpg";
// import SearchFriends from "../../assets/images/SearchFriends.jpg";
// import Yardages from "../../assets/images/Yardages.jpg";
// import MyClubs from "../../assets/images/MyClubs.jpg";
// import Preferences from "../../assets/images/Preferences.jpg";
// import Profile from "../../assets/images/Profile.jpg";
// import SocialRound from "../../assets/images/SocialRound.jpg";
// import SocialPost from "../../assets/images/SocialPost.jpg";
// import Notification from "../../assets/images/Notification.jpg";
// import Notifications from "../../assets/images/Notifications.jpg";
// import Message from "../../assets/images/Message.jpg";
// import Courses from "../../assets/images/Courses.jpg";

// const StyledContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
// `;

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 1.7rem;
// `;

// const FeatureSection = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
//   margin: 2rem 0;
//   text-align: center;

//   @media (max-width: 599px) {
//     flex-direction: column;
//   }
// `;

// const FeatureImage = styled.img`
//   flex: 1;
//   max-width: 300px;
//   border-radius: 15px;
//   box-shadow: 0 2px 40px 0px rgba(0, 0, 0, 0.3);
//   @media (max-width: 599px) {
//     max-width: 100%;
//   }
// `;

// const FeatureContent = styled.div`
//   flex: 1;
//   padding: 1rem;
// `;

// function Home() {
//   ScrollToTop();

//   const features = [
//     {
//       image: Caddy,
//       title: "Caddy Feature",
//       description: "Description of Caddy feature.",
//     },
//     {
//       image: ScorecardWMap,
//       title: "Scorecard with Map",
//       description: "Description of Scorecard with Map feature.",
//     },
//     {
//       image: MeasureShot,
//       title: "Measure Shot",
//       description: "Description of Measure Shot feature.",
//     },
//     {
//       image: ContinueRound,
//       title: "Continue Round",
//       description: "Description of Continue Round feature.",
//     },
//     {
//       image: DistanceToGreen,
//       title: "Distance to Green",
//       description: "Description of Distance to Green feature.",
//     },
//     {
//       image: Video,
//       title: "Video Feature",
//       description: "Description of Video feature.",
//     },
//     {
//       image: Video2,
//       title: "Video Feature 2",
//       description: "Description of Video feature 2.",
//     },
//     {
//       image: Practice,
//       title: "Practice",
//       description: "Description of Practice feature.",
//     },
//     {
//       image: PracticeLog,
//       title: "Practice Log",
//       description: "Description of Practice Log feature.",
//     },
//     {
//       image: LogStats,
//       title: "Log Stats",
//       description: "Description of Log Stats feature.",
//     },
//     {
//       image: Goals,
//       title: "Goals",
//       description: "Description of Goals feature.",
//     },
//     {
//       image: Notes,
//       title: "Notes",
//       description: "Description of Notes feature.",
//     },
//     {
//       image: Aimpoint,
//       title: "Aimpoint",
//       description: "Description of Aimpoint feature.",
//     },
//     {
//       image: Aimpoint2,
//       title: "Aimpoint 2",
//       description: "Description of Aimpoint 2 feature.",
//     },
//     {
//       image: Stats,
//       title: "Stats",
//       description: "Description of Stats feature.",
//     },
//     {
//       image: Stats2,
//       title: "Stats 2",
//       description: "Description of Stats 2 feature.",
//     },
//     {
//       image: FilterStats,
//       title: "Filter Stats",
//       description: "Description of Filter Stats feature.",
//     },
//     {
//       image: PutterStats,
//       title: "Putter Stats",
//       description: "Description of Putter Stats feature.",
//     },
//     {
//       image: PutterStats2,
//       title: "Putter Stats 2",
//       description: "Description of Putter Stats 2 feature.",
//     },
//     {
//       image: MapAndDistances,
//       title: "Map and Distances",
//       description: "Description of Map and Distances feature.",
//     },
//     {
//       image: ShotStats,
//       title: "Shot Stats",
//       description: "Description of Shot Stats feature.",
//     },
//   ];

//   return (
//     <StyledContainer>
//       <StyledH1>Most complete Golf App?</StyledH1>
//       {features.map((feature, index) => (
//         <FeatureSection key={index} reverse={index % 2 !== 0}>
//           <FeatureImage src={feature.image} alt={feature.title} />
//           <FeatureContent>
//             <h2>{feature.title}</h2>
//             <p>{feature.description}</p>
//           </FeatureContent>
//         </FeatureSection>
//       ))}
//     </StyledContainer>
//   );
// }

// export default Home;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import Caddy from "../../assets/images/Caddy.jpg";
import ScorecardWMap from "../../assets/images/Scorecard-w-map.jpg";
import MeasureShot from "../../assets/images/Measure-shot.jpg";
import ContinueRound from "../../assets/images/Continue-round.jpg";
import DistanceToGreen from "../../assets/images/Distance-to-green.jpg";
import Video from "../../assets/images/Video.jpg";
import Video2 from "../../assets/images/Video-2.jpg";
import Practice from "../../assets/images/Practice.jpg";
import PracticeLog from "../../assets/images/Practice-log.jpg";
import LogStats from "../../assets/images/Log-stats.jpg";
import Goals from "../../assets/images/Goals.jpg";
import Notes from "../../assets/images/Notes.jpg";
import Aimpoint from "../../assets/images/Aimpoint.jpg";
import ToTop from "../../assets/images/toTop.png";
import Stats from "../../assets/images/Stats.jpg";
import Stats2 from "../../assets/images/Stats-2.jpg";
import FilterStats from "../../assets/images/Filter-stats.jpg";
import PutterStats from "../../assets/images/Putterstats.jpg";
import PutterStats2 from "../../assets/images/Putterstats-2.jpg";
import MapAndDistances from "../../assets/images/Map-and-distances.jpg";
import ShotStats from "../../assets/images/Shot-stats.jpg";
import SelectTee from "../../assets/images/SelectTee.jpg";
import SearchCourses from "../../assets/images/SearchCourses.jpg";
import SearchFriends from "../../assets/images/SearchFriends.jpg";
import Yardages from "../../assets/images/Yardages.jpg";
import MyClubs from "../../assets/images/MyClubs.jpg";
import Preferences from "../../assets/images/Preferences.jpg";
import Profile from "../../assets/images/Profile.jpg";
import SocialRound from "../../assets/images/SocialRound.jpg";
import SocialPost from "../../assets/images/SocialPost.jpg";
import Notification from "../../assets/images/Notification.jpg";
import Notifications from "../../assets/images/Notifications.jpg";
import Message from "../../assets/images/Message.jpg";
import Courses from "../../assets/images/Courses.jpg";

const StyledImg = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  width: 5vw;
  min-width: 50px;
  max-width: 70px;
`;

const StyledContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  margin: 2rem 0 5rem;
`;

const FeatureSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  margin: 2rem 0;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

const FeatureImage = styled.img`
  flex: 1;
  max-width: 300px;
  border-radius: 15px;
  box-shadow: 0 5px 40px 0px rgba(0, 0, 0, 0.3);
  object-fit: contain;
  height: auto;

  @media (max-width: 699px) {
    max-width: 90%;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 1rem 3rem;
`;

function Home() {
  const [showToTop, setShowToTop] = useState(false);
  ScrollToTop();

  // Track scrolling to determine when to show the "Back to Top" button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setShowToTop(true);
      } else {
        setShowToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const features = [
    {
      image: Courses,
      title: "Courses",
      description: "Explore over 42 000 golf courses worldwide.",
    },
    {
      image: ContinueRound,
      title: "Continue Round",
      description: "Pause and resume your round anytime.",
    },
    {
      image: SearchCourses,
      title: "Search Courses",
      description: "Search and select courses worldwide.",
    },
    {
      image: SelectTee,
      title: "Select Tee",
      description: "Choose your starting tee with ease.",
    },
    {
      image: ScorecardWMap,
      title: "Scorecard with Map",
      description:
        "Visualize your game with an interactive scorecard and course map with distances to greens and hazards.",
    },
    {
      image: Caddy,
      title: "Caddy Feature",
      description: "Get real-time advice and suggestions for your next shot.",
    },

    {
      image: MeasureShot,
      title: "Measure Shot",
      description: "Accurately measure the distance of your shots.",
    },

    {
      image: DistanceToGreen,
      title: "Distance to Green",
      description:
        "Know exactly how far you are from the front, center and back green.",
    },
    {
      image: MapAndDistances,
      title: "Map and Distances",
      description: "Get accurate distances on course maps.",
    },
    {
      image: ShotStats,
      title: "Shot Statistics",
      description: "Input shot information for round statistics later.",
    },
    {
      image: Video,
      title: "Video Analysis",
      description: "Record and analyze your swing with video features.",
    },
    {
      image: Video2,
      title: "Advanced Video Tools",
      description:
        "Use advanced tools like slow-motion and custom drawn lines to improve your technique.",
    },
    {
      image: Practice,
      title: "Practice",
      description: "Hone your skills in practice sessions and shot statistics.",
    },
    {
      image: PracticeLog,
      title: "Practice Log",
      description: "Log your practice sessions.",
    },
    {
      image: LogStats,
      title: "Log Stats",
      description: "Review your practice durations.",
    },
    {
      image: Goals,
      title: "Set Goals",
      description: "Set and achieve your personal golf goals.",
    },
    {
      image: Notes,
      title: "Notes",
      description: "Keep notes on courses, techniques, and more.",
    },
    {
      image: Aimpoint,
      title: "Aimpoint Technology",
      description:
        "Improve your putting with Aimpoint insights for precise green and slope readings.",
    },
    {
      image: Stats,
      title: "Comprehensive Stats",
      description:
        "Analyze every aspect of your game and learn from your misses.",
    },
    {
      image: Stats2,
      title: "Detailed Statistics",
      description: "Dive deep into your performance metrics.",
    },
    {
      image: FilterStats,
      title: "Filter Stats",
      description: "Customize stats based on various filters.",
    },
    {
      image: PutterStats,
      title: "Putter Stats",
      description: "Track your putting performance and learn your misses.",
    },
    {
      image: PutterStats2,
      title: "Advanced Putter Stats",
      description: "Advanced analytics for putting.",
    },

    {
      image: SearchFriends,
      title: "Search Friends",
      description: "Connect with friends from all around the world.",
    },
    {
      image: Yardages,
      title: "Club Yardages",
      description: "Manage and view your club yardages.",
    },
    {
      image: MyClubs,
      title: "My Clubs",
      description: "Keep an inventory of your golf clubs.",
    },
    {
      image: Preferences,
      title: "Preferences",
      description: "Customize your app settings.",
    },
    {
      image: Profile,
      title: "Profile",
      description: "Manage your personal profile.",
    },
    {
      image: SocialRound,
      title: "Social Round",
      description: "View, like and comment on your friends golf rounds.",
    },
    {
      image: SocialPost,
      title: "Social Posts",
      description: "View, like and comment on your friends posts.",
    },
    {
      image: Notifications,
      title: "Notifications Center",
      description: "View all your notifications in one place.",
    },
    {
      image: Notification,
      title: "Notification",
      description: "Stay updated with instant notifications.",
    },

    {
      image: Message,
      title: "Messaging",
      description: "Chat with friends from all around the world.",
    },
  ];

  const HandleScrollToTop = () => {
    // Smooth scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <StyledContainer>
      <StyledH1>Most feature-packed golf app..? Ever?</StyledH1>
      {features.map((feature, index) => (
        <FeatureSection key={index} reverse={index % 2 !== 0}>
          <FeatureImage src={feature.image} alt={feature.title} />
          <FeatureContent>
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
          </FeatureContent>
        </FeatureSection>
      ))}

      {showToTop && (
        <StyledImg
          src={ToTop}
          alt="Back to Top"
          onClick={HandleScrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
        />
      )}
    </StyledContainer>
  );
}

export default Home;
