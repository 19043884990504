// import React, { useState } from "react";
// import styled from "styled-components";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import PopupMessage from "../../Components/PopupMessage/PopupMessage.jsx";
// import Loader from "../../Components/Loader/Loader.jsx";
// import Box from "@mui/material/Box";
// import Input from "@mui/material/Input";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// const CONTACT_API = process.env.REACT_APP_CONTACT_API;

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 1.7rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
//   margin-bottom: 2rem;
// `;

// const ContactLink = styled.a`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-decoration: none;
//   color: black;
//   gap: 0.25rem;
// `;

// const ContactText = styled.span`
//   font-family: "RobotoRegular", Arial, Helvetica, sans-serif;
//   font-size: 16px;
//   width: 200px;
//   text-align: center;
// `;

// const StyledMailOutlineIcon = styled(MailOutlineIcon)`
//   font-size: 14px;
// `;

// const StyledFormControl = styled(FormControl)`
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   gap: 2.5rem;

//   @media (max-width: 750px) {
//     width: 90%;
//   }
// `;

// const StyledBox = styled(Box)`
//   width: 80%;
//   margin: 0 auto;
//   display: flex;
//   justify-content: space-evenly;
//   gap: 3rem;

//   @media (max-width: 750px) {
//     flex-direction: column;
//     align-items: center;
//     width: 100%;
//   }
// `;

// const StyledButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 50px;
//   background-color: #62abc2;
//   color: white;
//   border-radius: 500px;
//   padding: 0 2rem;
//   cursor: pointer;
// `;

// const StyledInput = styled(Input)`
//   .MuiInputBase-input::placeholder {
//     font-weight: 500;
//     font-style: italic;
//   }
// `;

// const StyledTextArea = styled(TextField)`
//   .MuiInputBase-input::placeholder {
//     font-weight: 500;
//     font-style: italic;
//   }
// `;

// function Contact() {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");
//   const [showPopupMessage, setShowPopupMessage] = useState(false); // For success message
//   const [showLoader, setShowLoader] = useState(false); // For loader during submission
//   const [nameError, setNameError] = useState(false);
//   const [emailError, setEmailError] = useState(false);
//   const [messageError, setMessageError] = useState(false);

//   ScrollToTop();

//   const validateEmail = (email) => {
//     const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return pattern.test(email.trim());
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     let valid = true;

//     if (name.length < 3) {
//       setNameError(true);
//       valid = false;
//     } else {
//       setNameError(false);
//     }

//     if (!validateEmail(email)) {
//       setEmailError(true);
//       valid = false;
//     } else {
//       setEmailError(false);
//     }

//     if (message.length < 3) {
//       setMessageError(true);
//       valid = false;
//     } else {
//       setMessageError(false);
//     }

//     if (valid) {
//       setShowLoader(true);

//       try {
//         const response = await fetch(CONTACT_API, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             name: name,
//             email: email,
//             message: message,
//           }),
//         });

//         const data = await response.json();
//         setShowLoader(false);

//         if (response.ok) {
//           setShowPopupMessage(true); // Show success message
//           setTimeout(() => {
//             setShowPopupMessage(false); // Hide success message after 2s
//           }, 2000);

//           // Reset form fields
//           setName("");
//           setEmail("");
//           setMessage("");
//         } else {
//           console.error("Error sending email:", data.error);
//         }
//       } catch (error) {
//         console.error("Failed to send email:", error);
//         setShowLoader(false);
//       }
//     }
//   };

//   return (
//     <>
//       <StyledH1>Contact us</StyledH1>
//       {!showLoader && (
//         <StyledBox>
//           <StyledFormControl variant="standard">
//             <FormControl>
//               <StyledInput
//                 id="inputName"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 error={nameError}
//                 startAdornment={
//                   <InputAdornment position="start">
//                     <AccountCircle />
//                   </InputAdornment>
//                 }
//                 sx={{
//                   "&:before": {
//                     borderBottomColor: nameError ? "red" : "gray",
//                   },
//                   "&:after": {
//                     borderBottomColor: nameError ? "red" : "#62abc2",
//                   },
//                 }}
//               />
//               {nameError && (
//                 <span style={{ color: "red" }}>Name is required.</span>
//               )}
//             </FormControl>

//             <FormControl>
//               <StyledInput
//                 id="inputEmail"
//                 placeholder="Your email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 error={emailError}
//                 startAdornment={
//                   <InputAdornment position="start">
//                     <MailOutlineIcon />
//                   </InputAdornment>
//                 }
//                 sx={{
//                   "&:before": {
//                     borderBottomColor: emailError ? "red" : "gray",
//                   },
//                   "&:after": {
//                     borderBottomColor: emailError ? "red" : "#62abc2",
//                   },
//                 }}
//               />
//               {emailError && (
//                 <span style={{ color: "red" }}>Valid email is required.</span>
//               )}
//             </FormControl>

//             <StyledTextArea
//               id="inputMessage"
//               placeholder="Message"
//               value={message}
//               onChange={(e) => setMessage(e.target.value)}
//               multiline
//               rows={3}
//               variant="standard" // Use standard variant for underline style
//               error={messageError}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <HelpOutlineIcon style={{ marginTop: -48 }} />
//                   </InputAdornment>
//                 ),
//               }}
//               sx={{
//                 "& .MuiInput-underline:before": {
//                   borderBottomColor: messageError ? "red" : "gray", // Initial border color
//                 },
//                 "& .MuiInput-underline:after": {
//                   borderBottomColor: messageError ? "red" : "#62abc2", // Custom color when in focus
//                 },
//                 "& .Mui-focused:after": {
//                   borderBottomColor: "#62abc2", // Ensure custom color on focus
//                 },
//                 "& .MuiInputBase-root": {
//                   borderBottom: "none", // Ensure no full border is shown
//                 },
//               }}
//             />

//             {messageError && (
//               <span style={{ color: "red" }}>Message is required.</span>
//             )}

//             <StyledButton onClick={handleSubmit}>Send message</StyledButton>
//           </StyledFormControl>
//         </StyledBox>
//       )}
//       {showPopupMessage && <PopupMessage message="Message sent successfully" />}
//       {showLoader && <Loader />}
//     </>
//   );
// }

// export default Contact;

import React, { useState } from "react";
import styled from "styled-components";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import PopupMessage from "../../Components/PopupMessage/PopupMessage.jsx";
import Loader from "../../Components/Loader/Loader.jsx";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const CONTACT_API = process.env.REACT_APP_CONTACT_API;

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.6rem;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 3rem;
`;

const StyledFormControl = styled(FormControl)`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (max-width: 750px) {
    width: 90%;
  }
`;

const StyledBox = styled(Box)`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #62abc2;
  color: white;
  border-radius: 500px;
  padding: 0 2rem;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  .MuiInputBase-input::placeholder {
    font-weight: 500;
    font-style: italic;
  }
`;

const StyledTextArea = styled(TextField)`
  .MuiInputBase-input::placeholder {
    font-weight: 500;
    font-style: italic;
  }
`;

const ConsentCheckboxWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  margin-top: -1.5rem;
`;

const ConsentLabel = styled.label`
  font-size: 1rem;
`;

const ConsentCheckbox = styled.input`
  cursor: pointer;
`;

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [consent, setConsent] = useState(false); // State to track checkbox
  const [showPopupMessage, setShowPopupMessage] = useState(false); // For success message
  const [showLoader, setShowLoader] = useState(false); // For loader during submission
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [consentError, setConsentError] = useState(false); // State to track consent error

  ScrollToTop();

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;

    if (name.length < 3) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }

    if (message.length < 3) {
      setMessageError(true);
      valid = false;
    } else {
      setMessageError(false);
    }

    // Check if consent is given
    if (!consent) {
      setConsentError(true);
      valid = false;
    } else {
      setConsentError(false);
    }

    if (valid) {
      setShowLoader(true);

      try {
        const response = await fetch(CONTACT_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            message: message,
          }),
        });

        const data = await response.json();
        setShowLoader(false);

        if (response.ok) {
          setShowPopupMessage(true); // Show success message
          setTimeout(() => {
            setShowPopupMessage(false); // Hide success message after 2s
          }, 2000);

          // Reset form fields
          setName("");
          setEmail("");
          setMessage("");
          setConsent(false); // Reset the consent checkbox
        } else {
          console.error("Error sending email:", data.error);
        }
      } catch (error) {
        console.error("Failed to send email:", error);
        setShowLoader(false);
      }
    }
  };

  return (
    <>
      <StyledH1>Contact us</StyledH1>
      {!showLoader && (
        <StyledBox>
          <StyledFormControl variant="standard">
            <FormControl>
              <StyledInput
                id="inputName"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={nameError}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                sx={{
                  "&:before": {
                    borderBottomColor: nameError ? "red" : "gray",
                  },
                  "&:after": {
                    borderBottomColor: nameError ? "red" : "#62abc2",
                  },
                }}
              />
              {nameError && (
                <span style={{ color: "red" }}>Name is required.</span>
              )}
            </FormControl>

            <FormControl>
              <StyledInput
                id="inputEmail"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                startAdornment={
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                }
                sx={{
                  "&:before": {
                    borderBottomColor: emailError ? "red" : "gray",
                  },
                  "&:after": {
                    borderBottomColor: emailError ? "red" : "#62abc2",
                  },
                }}
              />
              {emailError && (
                <span style={{ color: "red" }}>Valid email is required.</span>
              )}
            </FormControl>

            <StyledTextArea
              id="inputMessage"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={3}
              variant="standard" // Use standard variant for underline style
              error={messageError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HelpOutlineIcon style={{ marginTop: -48 }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: messageError ? "red" : "gray", // Initial border color
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: messageError ? "red" : "#62abc2", // Custom color when in focus
                },
                "& .Mui-focused:after": {
                  borderBottomColor: "#62abc2", // Ensure custom color on focus
                },
                "& .MuiInputBase-root": {
                  borderBottom: "none", // Ensure no full border is shown
                },
              }}
            />

            {messageError && (
              <span style={{ color: "red" }}>Message is required.</span>
            )}

            {/* Consent Checkbox */}
            <ConsentCheckboxWrapper>
              <ConsentCheckbox
                type="checkbox"
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: 0.5,
                  marginLeft: 1,
                }}
              />
              <ConsentLabel>
                I consent to MaxBogey using my name and email to contact me in
                response to my inquiry.
              </ConsentLabel>
            </ConsentCheckboxWrapper>
            {consentError && (
              <span style={{ color: "red" }}>
                You must agree to provide consent.
              </span>
            )}

            <StyledButton onClick={handleSubmit}>Send message</StyledButton>
          </StyledFormControl>
        </StyledBox>
      )}
      {showPopupMessage && <PopupMessage message="Message sent successfully" />}
      {showLoader && <Loader />}
    </>
  );
}

export default Contact;
