// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import styled from "styled-components";
// import { Link, useNavigate } from "react-router-dom";
// import { useMediaQuery } from "@mui/material";

// import Slagord from "../../assets/images/ventralSlogan.png";
// import Logo from "../../assets/images/ventralLogo2.png";
// import MenuIcon from "@mui/icons-material/Menu";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import Slide from "@mui/material/Slide";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const StyledNav = styled.nav`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
// `;

// const LogoDiv = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 95vw;
//   max-width: 1500px;
//   margin: 2rem 0rem 3rem;

//   @media (max-width: 1099px) {
//     margin: 1rem 0rem;
//   }
// `;

// const StyledLink = styled(Link)`
//   text-decoration: none;
//   color: black;
//   font-size: 1.5rem;
//   display: flex;
//   justify-content: center;
// `;

// const StyledTab = styled(Tab)`
//   flex-grow: 1;
// `;

// const StyledLogo = styled.img`
//   width: 140px;
//   cursor: pointer;
//   margin-left: 1rem;
// `;

// const StyledSlogan = styled.img`
//   width: 30vw;
//   max-width: 500px;
//   min-width: 250px;
//   cursor: pointer;

//   @media (max-width: 699px) {
//     display: none;
//   }
// `;

// const StyledBox = styled(Box)`
//   @media (max-width: 1099px) {
//     display: none;
//   }
// `;

// const navLinks = [
//   { label: "Hjem", to: "/" },
//   { label: "Pasientveileder", to: "/pasientveileder" },
//   { label: "Copycat", to: "/copycat" },
//   { label: "Ledig stilling", to: "/ledig-stilling" },
//   { label: "Om oss", to: "/om-oss" },
//   { label: "Kontakt oss", to: "/kontakt-oss" },
// ];

// function Nav() {
//   const [value, setValue] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleNavigation = (index) => {
//     setValue(index);
//     handleCloseDialog();
//   };

//   // const handleHomeClick = () => {
//   // const HjemIndex = navLinks.findIndex((link) => link.label === "Hjem");
//   // if (HjemIndex !== -1) {
//   //   setValue(HjemIndex);
//   // }
//   // };

//   return (
//     <>
//       <StyledNav>
//         <LogoDiv>
//           <StyledLogo
//             src={Logo}
//             alt="Logo"
//             onClick={() => {
//               window.location.href = "/";
//             }}
//           />
//           <StyledSlogan
//             src={Slagord}
//             alt="Slagord"
//             onClick={() => {
//               window.location.href = "/";
//             }}
//           />

//           <MenuIcon
//             sx={{
//               fontSize: "2.5rem",
//               cursor: "pointer",
//               paddingRight: "1.5rem",
//               paddingLeft: "5.5rem",
//             }}
//             onClick={handleOpenDialog}
//           />
//         </LogoDiv>
//         <BasicTabs
//           navLinks={navLinks}
//           value={value}
//           handleChange={handleChange}
//         />
//         <NavigationDialog
//           open={openDialog}
//           onClose={handleCloseDialog}
//           navLinks={navLinks}
//           onNavigate={handleNavigation}
//         />
//       </StyledNav>
//     </>
//   );
// }

// function NavigationDialog({ open, onClose, navLinks, onNavigate }) {
//   const navigate = useNavigate();

//   const handleNavigate = (index) => {
//     onNavigate(index);
//     navigate(navLinks[index].to);
//   };

//   return (
//     <Dialog
//       open={open}
//       TransitionComponent={Transition}
//       keepMounted
//       onClose={onClose}
//       aria-labelledby="navigation-dialog-title"
//       aria-describedby="navigation-dialog-description"
//     >
//       <DialogContent>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "1.2rem",
//             backgroundColor: "transparent",
//             maxWidth: "350px",
//             margin: "0rem 3vw",
//           }}
//         >
//           {navLinks.map((link, index) => (
//             <div
//               key={index}
//               onClick={() => handleNavigate(index)}
//               style={{
//                 alignSelf: "center",
//                 borderRadius: "15px",
//                 textAlign: "center",
//                 backgroundColor: "#f8f9fa85",
//                 boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
//                 width: "70vw",
//                 cursor: "pointer",
//                 maxWidth: "280px",
//               }}
//             >
//               <h2
//                 style={{
//                   color: "#3A3A3A",
//                   fontSize: "1.3rem",
//                   fontWeight: "bold",
//                   fontFamily: "Arial",
//                 }}
//               >
//                 {link.label}
//               </h2>
//             </div>
//           ))}
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// }

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function BasicTabs({ navLinks, value, handleChange }) {
//   // Check if the screen width is more than 1099px
//   const isLargeScreen = useMediaQuery("(min-width:1099px)");

//   return (
//     <>
//       <StyledBox sx={{ width: "100%" }}>
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="basic tabs example"
//           TabIndicatorProps={{
//             style: {
//               backgroundColor: "#62ABC2", // Underline color
//             },
//           }}
//         >
//           {isLargeScreen &&
//             navLinks.map((link, index) => (
//               <StyledTab
//                 key={index}
//                 label={link.label}
//                 component={StyledLink}
//                 to={link.to}
//                 sx={{
//                   color: "black", // Default color for inactive tabs
//                   "&.Mui-selected": {
//                     color: "#62ABC2", // Color for the active tab text
//                     fontWeight: "bold", // Bold text for the active tab
//                   },
//                 }}
//               />
//             ))}
//         </Tabs>
//       </StyledBox>

//       {!isLargeScreen &&
//         navLinks.map((link, index) => (
//           <CustomTabPanel key={index} value={value} index={index}>
//             <StyledLink to={link.to}>{link.label}</StyledLink>
//           </CustomTabPanel>
//         ))}
//     </>
//   );
// }

// BasicTabs.propTypes = {
//   navLinks: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       to: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   value: PropTypes.number.isRequired,
//   handleChange: PropTypes.func.isRequired,
// };

// export default Nav;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery, Drawer } from "@mui/material";

import Banner from "../../assets/images/banner.png";
import Logo from "../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const LogoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  max-width: 1500px;
  margin: 2rem 0rem 3rem;

  @media (max-width: 1099px) {
    margin: 1rem 0rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
`;

const StyledTab = styled(Tab)`
  flex-grow: 1;
`;

const StyledLogo = styled.img`
  height: 8vw;
  max-height: 35px;
  cursor: pointer;
`;

const StyledSlogan = styled.img`
  width: 30%;
  min-width: 140px;
  max-width: 200px;
  cursor: pointer;
  margin-top: 0.3rem;

  @media (min-width: 699px) {
    margin-top: 0.5rem;
  }

  @media (min-width: 1099px) {
    margin-top: 0;
  }
`;

const StyledBox = styled(Box)`
  @media (max-width: 1099px) {
    display: none;
  }
`;

const navLinks = [
  { label: "Home", to: "/" },
  { label: "Terms", to: "/terms" },
  { label: "About us", to: "/about-us" },
  { label: "FAQ", to: "/faq" },

  { label: "Contact", to: "/contact" },
];

function Nav() {
  const [value, setValue] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleNavigation = (index) => {
    setValue(index);
    handleCloseDrawer();
  };

  return (
    <>
      <StyledNav>
        <LogoDiv>
          <StyledLogo
            src={Logo}
            alt="Logo"
            onClick={() => {
              window.location.href = "/";
            }}
          />
          <StyledSlogan
            src={Banner}
            alt="Banner"
            onClick={() => {
              window.location.href = "/";
            }}
          />

          <MenuIcon
            sx={{
              fontSize: "2.5rem",
              cursor: "pointer",
            }}
            onClick={handleOpenDrawer}
          />
        </LogoDiv>
        <BasicTabs
          navLinks={navLinks}
          value={value}
          handleChange={handleChange}
        />
        <NavigationDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          navLinks={navLinks}
          onNavigate={handleNavigation}
        />
      </StyledNav>
    </>
  );
}

function NavigationDrawer({ open, onClose, navLinks, onNavigate }) {
  const navigate = useNavigate();

  const handleNavigate = (index) => {
    onNavigate(index);
    navigate(navLinks[index].to);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { backgroundColor: "transparent", boxShadow: "none" },
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          backgroundColor: "transparent",
          padding: "1.5rem",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        {navLinks.map((link, index) => (
          <div
            key={index}
            onClick={() => handleNavigate(index)}
            style={{
              borderRadius: "15px",
              textAlign: "center",
              backgroundColor: "white",
              boxShadow: "0 1.2px 1.5px 0 rgba(0,0,0,0.3)",
              width: "85%",
              maxWidth: "500px",
              cursor: "pointer",
              opacity: "0.98",
            }}
          >
            <h2
              style={{
                color: "#3A3A3A",
                fontSize: "1rem",
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              {link.label}
            </h2>
          </div>
        ))}
      </Box>
    </Drawer>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function BasicTabs({ navLinks, value, handleChange }) {
  const isLargeScreen = useMediaQuery("(min-width:1099px)");

  return (
    <>
      <StyledBox sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#62ABC2", // Underline color
            },
          }}
        >
          {isLargeScreen &&
            navLinks.map((link, index) => (
              <StyledTab
                key={index}
                label={link.label}
                component={StyledLink}
                to={link.to}
                sx={{
                  textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",

                  color: "black", // Default color for inactive tabs
                  "&.Mui-selected": {
                    color: "#62ABC2", // Color for the active tab text
                    fontWeight: "bold", // Bold text for the active tab
                  },
                }}
              />
            ))}
        </Tabs>
      </StyledBox>

      {/* {!isLargeScreen &&
        navLinks.map((link, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            <StyledLink to={link.to}>{link.label}</StyledLink>
          </CustomTabPanel>
        ))} */}
    </>
  );
}

BasicTabs.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Nav;
